import { GetStaticProps } from 'next';

import { getEventPage } from '@/services/event.service';

import AiSummitPage from '@/components/event/AiSummitPage';

export default function Event({ content }: any) {
  return <AiSummitPage content={content} />;
}

export const getStaticProps: GetStaticProps = async () => {
  try {
    const content = await getEventPage({ slug: 'ai-summit' });

    if (!content) {
      return {
        notFound: true,
        revalidate: 60,
      };
    }

    return {
      props: {
        content,
      },
      revalidate: 60,
    };
  } catch (error: any) {
    if (error?.status === 404) {
      return {
        notFound: true,
        revalidate: 60,
      };
    }

    throw new Error(`Failed to fetch ai summit data`);
  }
};
