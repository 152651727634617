import { useMemo } from 'react';

import { Layout, SEO } from '@/components/core';
import { Join, MoreInfo } from '@/components/homepage';
import {
  Countdown,
  RegistrationSection,
  Event,
  Venue,
  Agenda,
  Sponsors,
  KeynoteSpeaker,
} from '@/components/vision';
import { Video } from '@/components/shared';
import Navigation from '@/components/vision/Navigation/Navigation';
import { EventProvider } from '@/contexts/event';
import { EventPageContent } from '@/types/event';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    --body-font-weight: 400;
    --title-font: var(--ui-font-family-space-mono);
    --title-font-weight: 400;
    --title-text-transform: uppercase;
    --background-color: ${({ theme }) => theme.eventColors.bg};
    --nav-bg: ${({ theme }) => theme.eventColors.navBg};
    --nav-color: ${({ theme }) => theme.eventColors.navColor};
    --nav-color-scrolled: ${({ theme }) => theme.eventColors.navColorScrolled};
    --agenda-nav-bg: ${({ theme }) => theme.eventColors.agendaNavBg};
    --agenda-nav-hover-color: ${({ theme }) => theme.eventColors.agendaNavHoverColor};
    --agenda-nav-active-color: ${({ theme }) => theme.eventColors.agendaNavActiveColor};
    --hours-color: ${({ theme }) => theme.eventColors.hoursColor};
    --text-color: ${({ theme }) => theme.eventColors.textColor};
    --label-color: ${({ theme }) => theme.eventColors.labelColor};
    --link-color: ${({ theme }) => theme.eventColors.linkColor};
    --link-hover-color:  ${({ theme }) => theme.eventColors.linkHoverColor};
    --title-color: ${({ theme }) => theme.eventColors.titleColor};
    --title-color-primary: ${({ theme }) => theme.eventColors.titleColorPrimary};
    --button-text-transform: uppercase;
    --primary-button-color: ${({ theme }) => theme.eventColors.primaryButtonColor};
    --primary-button-bg: ${({ theme }) => theme.eventColors.primaryButtonBg};
    --primary-button-hover-color: ${({ theme }) => theme.eventColors.primaryButtonHoverColor};
    --primary-button-hover-bg: ${({ theme }) => theme.eventColors.primaryButtonHoverBg};
    --secondary-button-border: ${({ theme }) => theme.eventColors.secondaryButtonBorder};
    --secondary-button-hover-color: ${({ theme }) => theme.eventColors.secondaryButtonHoverColor};
    --secondary-button-hover-bg: ${({ theme }) => theme.eventColors.secondaryButtonHoverBg};
    --tertiary-button-border: ${({ theme }) => theme.eventColors.tertiaryButtonBorder};
    --tertiary-button-color: ${({ theme }) => theme.eventColors.tertiaryButtonColor};
    --tertiary-button-bg: ${({ theme }) => theme.eventColors.tertiaryButtonBg};
    --tertiary-button-hover-border: ${({ theme }) => theme.eventColors.tertiaryButtonHoverBorder};
    --tertiary-button-hover-color: ${({ theme }) => theme.eventColors.tertiaryButtonHoverColor};
    --tertiary-button-hover-bg: ${({ theme }) => theme.eventColors.tertiaryButtonHoverBg};
    --white-button-hover-bg: ${({ theme }) => theme.eventColors.whiteButtonHoverBg};
    --highlight-color: ${({ theme }) => theme.eventColors.highlightColor};
    --error-color: ${({ theme }) => theme.eventColors.errorColor};
    --background-icon-more-info-animated: rgba(31, 34, 44, 1);
    --background-more-info-animated: rgba(29, 56, 97, 0.19);

    background-color:  var(--background-color);
  }
`;

export default function AiSummitPage({ content, preview }: EventPageContent) {
  const showAgenda = useMemo(() => !content?.agenda?.hideAgenda || false, [content]);

  return (
    <>
      <GlobalStyle />
      <EventProvider content={content}>
        <SEO seo={content?.seo} preview={preview} theme={content?.theme} />
        <Layout
          navigation={Navigation}
          navigationProps={{ showAgenda, customLogo: content?.logo }}
          bannerInfo={{ ...content.banner, updatedAt: content.updatedAt }}
          withoutFooter
        >
          <Countdown hasScrollIndicator={false} />
          <Video />
          <KeynoteSpeaker />
          <Agenda showSchedule={false} id="speakers" />
          <RegistrationSection registration={content.registration} />
          <Event />
          <Agenda showIntro={false} spacerTop={false} />
          <Venue />
          <Sponsors title={content?.sponsorsTitle} list={content?.sponsorsList} />
          <MoreInfo isAnimation />
          <Join minimal />
        </Layout>
      </EventProvider>
    </>
  );
}
